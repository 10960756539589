.menuAside{
    max-width: 250px;
    background-color: var(--white);
}

.menuAside_btn{
    display: none !important; 
}

@media (max-width: 750px) {
    .menuAside{
        transition: 0.25s;
        position: absolute;
        z-index: 1;
        height: 100%;
        left: -100%;
    }

    .menuAside_btn{
        display: flex !important;    
    }

    .menuAside_active{
        left: 0;
    }
}
@media (max-width: 550px) {
    .menuAside{
        max-width: none;
    }
}