.sectionElevation{
    position: relative;
    z-index: 1;
    /* max-width:90% */
    max-width:calc(100% - 16px)
}

.smw{
    max-width:100%
}

.sectionElevation .sectionElevation_paper{
    position: absolute;
}
.sectionElevation .sectionElevation_paper_relative{
    position: relative;
}

@media (max-width: 740px) {
    .sectionElevation_info{
        display: none;
    }
}

@media (max-height: 500px) {
    .sectionElevation_info{
        display: none;
    }
}