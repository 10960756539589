
.siteContents-cardSection{
    flex-direction: column;
}

.siteContents-item{
    padding: 16px 0;
}

.siteContents-input{
    background: var(--gray);
    overflow: hidden;
    border-radius: 4px;
    padding: 0 8px;
}

.contentEditable{
    outline: none;
}

/* input underLine */
.siteContents .css-p2gaf0-MuiInputBase-root-MuiInput-root:before,
.siteContents .css-p2gaf0-MuiInputBase-root-MuiInput-root:after{
    display: none;
}

