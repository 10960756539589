
.sectionImage{
    min-height: 550px;
    position: relative;
}
/* 
.sectionImage ul {
    list-style-image: url('https://icons.iconarchive.com/icons/paomedia/small-n-flat/256/sign-check-icon.png');
} */
/* .sectionImage ul li::marker{
    font-size: 1.5em;
} */

/* .sectionImage .section_text span{
    display: inline-block;
    margin-top: 8px;
} */
.sectionImage .section_text h1{
    font-weight: 500 !important;
    font-size: 3rem;
    line-height: 1.167;
    letter-spacing: 0em;
    margin-top: 8px;
    cursor: pointer;
    white-space: pre-line;
    color: #000000
}
/* .sectionImage .public-DraftStyleDefault-block{
    display: inline-block;
    margin-bottom: 8px;
} */
/* .sectionImage ul li {
    list-style-type: none;
    margin-left: 16px !important;
    padding-right: 40px;
    display: flex;
    flex-direction: row;
}

.sectionImage ul li:before {
    content: url('../../assets//icons/chevrons-right-solid-24.png');
    height: fit-content;
    margin-right: 16px;
}

.sectionImage ol li {
    margin-left: 40px !important;
    padding-right: 40px;
}

.sectionImage ol li:before{
    color: var(--primary);
    font-weight: bolder;
    font-family: 'Montserrat', sans-serif !important;
    font-size: 18px;
} */

.sectionImage .containerImage{
    position: relative;
    height: 450px;
    /* min-height: 450px; */
    width: 400px;
    position: absolute;
    align-items: center;
}

.sectionImage .containerImage_image{
    width: 90%;
    height: auto;
    z-index: 100;
    max-width: 400px;
    aspect-ratio: 1.4;
    overflow: hidden;
    position: relative;
    box-shadow: 0px 3px 3px #00000040;
}


.sectionImage .containerImage_image .mediaDefault{
    width: 100%;
    height: auto;
    min-height: 100%;
    position: absolute;
    z-index: 1;
}
.sectionImage .containerImage_image img{
    width: 100%;
    height: auto;
    min-height: 100%;
}
.sectionImage .containerImage_image video{
    height: 100%;
    width: auto;
}

.sectionImage .containerImage_background{
    position: absolute;
    background-color: var(--primary);
    height: 100%;
    max-height: 400px;
    width: 80%;
}

.sectionImage .sectionImage_body .sectionImage_body_wrap{
    width:700px;
    max-width: 60vw;
}


.sectionImage .sectionImage_body_title{
    white-space:pre-line;
}

/* Gray */
.sectionGray{
    background-color: var(--gray);
}

.sectionWhite .sectionImage_body{
    align-items: flex-start;
}
.sectionGray .containerImage{
    right: 0;
}

.sectionGray .containerImage_background{
    border-radius: 100px 0 0 100px;
    right: 0;
}
.sectionGray .containerImage_image{
    border-radius: 90px 50px 90px 50px;
}


/* White */
.sectionWhite{
    background-color: var(--white);
}
.sectionWhite .sectionImage_body{
    align-items: flex-end;
}
.sectionWhite .containerImage{
    left: 0;
}

.sectionWhite .containerImage_image{
    border-radius: 40px 90px 40px 90px; 
}

.sectionWhite .containerImage_background{
    border-radius: 0 100px 100px 0;
    left: 0;
}


@media (min-width: 1200px) {
    .sectionGray .containerImage_background{
        border-radius: 100px 50px 100px 50px;
    }
    .sectionWhite .containerImage_background{
        border-radius: 50px 100px 50px 100px ;
    }
}


@media (max-width: 1005px) {
    .sectionImage .containerImage{
        /* min-height: 300px; */
        height: 100%;
        max-height: 18rem;
        width: 35vw;
        align-items: center;
    }

    .sectionImage .containerImage_image{
        /* width: 85%; */
        /* margin-left: auto */
    }
    .sectionGray .containerImage_background{
        border-radius: 5rem 0 0 5rem;
    }
    .sectionGray .containerImage_image{
        border-radius: 67.5px 35px 67.5px 35px;
        margin-right: auto
    }
    .sectionWhite .containerImage_background{
        border-radius: 0 5rem 5rem 0;
    }
    .sectionWhite .containerImage_image{
        border-radius: 35px 67.5px 35px 67.5px; 
        margin-left: auto
    }
}


@media (max-width: 750px) {
    .sectionImage .containerImage_background{
        max-height: 250px;
    }
}

@media (max-width: 650px) {

    .sectionImage{
        /* min-height: 100vh; */
    }    
    .sectionImage .sectionImage_body .sectionImage_body_wrap{
        width:100%;
        max-width: none;
    }

    .sectionImage .containerImage{
        margin-top: 40px;
        position: relative;
        max-height: none;
        height: 100%;
        /* max-height: x; */
        min-height: 40vw;
        width: 50vw;
        align-items: center;
    }
    .sectionGray .containerImage_background{
        border-radius: 60px 25px 60px 25px;
    }
    .sectionGray .containerImage_image{
        border-radius: 50px 25px  50px 25px;
    }
    .sectionWhite .containerImage_background{
        border-radius: 25px 60px 25px 60px ;
    }
    .sectionWhite .containerImage_image{
        border-radius: 25px 50px 25px 50px; 
    }

    .sectionImage .sectionImage_body_title{
        text-align: center !important;
    }
    /* .sectionImage h1{
        font-size: 2.5rem !important;
    } */
    /* .sectionImage ul li {
        margin-left: 0px !important;
        padding-left: 8px;
        padding-right: 20px;
    }
    .sectionImage ol li {
        margin-left: 16px !important;
        padding-left: 16px;
        padding-right: 20px !important;
    }
    .sectionImage ol li:before {
        margin-left: 10px;
    } */

    .sectionImage h1,
    .sectionImage h1 .public-DraftStyleDefault-block{
        align-items: center;
        justify-content: center;
        display: flex;
        text-align: center !important;
    }
}
