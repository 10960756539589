.jobsBySection{
    flex-wrap: wrap;
}

.itemSection_btn{
    border-radius: 16px !important
}

.jobsBySection .itemSection_chip{
    cursor: pointer !important;
    background-color: var(--white) !important;
}