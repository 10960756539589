.sectionElevation .containerBtn{
    text-transform: inherit;
}


@media (max-width: 600px) {
    .sectionElevationFindAJob_tab span{
        display: none;
    }
    .sectionElevationFindAJob_tab{
        min-height:auto !important;
    }
}
@media (max-width: 450px) {
    .sectionElevationFindAJob_tab{
        min-width: 20vw !important;
    }
}