.resetPassword{
    width: 100vw;
    height: calc(100vh - 58px);
    background: var(--primary);
}
.resetPassword img{
    width: 100px;
}

.resetPassword .actionInput{
    position: absolute;
    bottom: 0;
    right: 0;
}

.resetPassword  .resetPasswordoForm{
    width: 100px;
}

@media (max-width: 750px) {
    .resetPassword .containerTitleLog{
        display: none;
    }
    .resetPassword {
        width: 100%;
    }
}