
.articleEditor{
    /* height: 500px; */
    /* align-items: flex-start; */
    width: 100%;
    overflow: auto;
    margin-top: 10px;
    /* max-height: calc(100% - 134px);
    height: calc(100% - 134px); */
    /* height: auto; */
    padding-bottom: 16px;
    background: var(--white);
    width: calc(100% - 20px);
    max-width: 960px;
    border-radius: 24px
}

.resources .pagination{
    width: calc(100% - 20px);
    max-width: 960px;
}


.resources .btnAdddArticle{
    position: fixed;
    bottom: 16px;
    right: 2rem
}


@media (max-width: 750px) {
    .articleEditor{
        width:100%;
        margin-top: 0px;
        /* border-radius: 0 0 24px 24px; */
        border-radius: 0;
    }
}
