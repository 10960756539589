.cardArticleMin{
    background: var(--white);
    min-height: 100px;
    width: 100%;
    max-width: 500px;
    overflow: hidden;
    border-radius: 12px 12px 12px 30px !important;
    margin: 10px 0;
}
.cardArticleMin_containerImage,
.cardArticleMin_container,
.cardArticleMin button{
    width: 100%;
    height: 100%;
}

.cardArticleMin_containerImage{
    position: relative;
    overflow: hidden;
    border-radius: 12px 30px 12px 30px;
    min-height: 100px;
}

.cardArticleMin_containerImage video,
.cardArticleMin_containerImage img{
    position:absolute;
    height:auto;
    min-height: 100%;
    width:100%; 
    object-fit: cover;
    transition: 0.5s
}

.cardArticleMin:hover .cardArticleMin_containerImage video,
.cardArticleMin:hover .cardArticleMin_containerImage img{
    transform: scale(1.2);
    transition: 2s;
}

.cardArticleMin_date{
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top-right-radius: 12px;
    background-color: var(--primary);
    padding: 8px;
    opacity: 0.9;
}

.cardArticleMin:hover .cardArticleMin_date{
    display: flex;
}
@media (max-width: 550px) {
    .cardArticleMin_title{
        text-align: left !important;
    }
    .cardArticleMin_date{
        display: flex;
    }
}

/* @media (max-width: 920px)  */