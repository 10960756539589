
.cardArticleResponsive{
    height: auto;
    justify-content: space-between;
    align-items: center;
    position: relative;
    max-width: 450px;
    border-radius: 12px !important;
    overflow: hidden;
}
.cardArticleResponsive:hover{
    background: var(--gray);
}

.cardArticleResponsive .container_date{
    position: absolute;
    height: 40px;
    padding: 5px 10px;
    top: -40px;
    left: 0;
    background-color: var(--primary);
    border-top-right-radius: 5px;
    z-index: 2;
}

/* @media (min-width: 661px) {
    .cardArticleResponsive{
        display:none !important
    }
  } */

  .cardArticleResponsive .mediaDefault{
    z-index: 1;
    height: 140px;
    width: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
  }
  .cardArticleResponsive .mediaDefault img{
    width: 100%;
    height: auto;
    min-height: 100%;
  }

  .cardArticleResponsive-video{
    width: 100%;
    height: 140px;
    position: relative;
    overflow: hidden;
}

.cardArticleResponsive-video video{
    position: absolute;
    height: auto;
    width: 100%;
    min-height: 100%;
  }
