.socialMedia-header{
    flex-direction: column;
    position: absolute;
    bottom: 16px;
    left: 1vw;
    z-index: 100;
    /* background-color: rgba(0, 0, 0, 0.2); */
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 50px;
    padding: 8px 0;
    backdrop-filter: blur(5px);
    height: 200px;
    justify-content: space-evenly;
    transition: 0.25s;
}
.socialMedia-header .bounce:hover svg{
    color: var(--primary);
}

.socialMedia-campaign{
    gap: 20px;
}
.socialMedia-footer svg{
    color: white;
}

.social-campaign-avatar{
    margin-top: 20px;
}
.social-campaign-avatar svg{
    height: 36px;
    width: 36px;
    color: white; 
}

@media (max-width: 1260px) {
    /* .socialMedia-header{
        flex-direction: row;
        right: initial;
        height: fit-content;
        width: 200px;
        bottom:140px;
    } */
    .socialMedia-header{
        bottom: initial;
        top: 16px;
    }
    
}

@media (max-width: 900px) {
    /* .socialMedia-header{
        bottom: 160px;
    } */
}

@media (max-width: 740px) {
    .socialMedia-header{
        flex-direction: row;
        height: fit-content;
        left: initial;
        bottom: 8px;
        width: 220px;
    }
}