
.cardArticle{
    height: auto;
    justify-content: space-between;
    align-items: center;
    position: relative;
    border-radius: 12px !important;
    overflow: hidden;
}
.cardArticle:hover{
    background: var(--gray);
}

.cardArticle .imageArticle{
    width:100%; 
    height:100%
}
.cardArticle .imageArticle{
    width:190px;
    min-width: 190px; 
    height:140px;
    position: relative;
}

.cardArticle .imageArticle_background{
    position:absolute;
    width:170px;
    height:100%;
    background:var(--primary);
    border-radius:24px 48px 24px 98px;
    left: 0;
}

.cardArticle .imageArticle_image{
    position: absolute;
    width:140px; 
    height:120px;
    right:0px;
    border-radius:24px 48px 24px 64px;
    overflow: hidden;
    box-shadow: 0px 3px 3px #00000040;
}

.cardArticle .imageArticle_image .mediaDefault{
    width: 100%;
    height: auto;
    min-height: 100%;
    position: absolute;
    z-index: 1;
}

.cardArticle .imageArticle_image img{
    width: 100%;
    height: auto;
    min-height: 100%;
}


.cardArticle .imageArticle_image video{
    width: auto;
    height: 100%;
}

.cardArticle .bodyCard{
    height: auto;
    width: 100%;
    text-align: initial;
}

.cardArticle .imageArticle_date{
    position: absolute;
    width: 50px;
    height: 70px;
    left: 0;
    top: 10px;
    color: var(--white);
}

.cardArticle .imageArticle_date .text-date{
    line-height: 1.2;
    margin-right: 8px;
}

.cardArticle .imageArticle_date .month-date{
    font-size: 16px;
}

.cardArticle .imageArticle_date .day-date{
    font-size: 24px;
}

.cardArticle .imageArticle_date .year-date{
    font-size: 9.6px;
}

.cardArticle .card-switch{
    position: absolute !important;
    top: 15px;
    right: 20px;
}

/* @media (max-width: 660px) {
    .cardArticle{
        display:none !important
    }
  } */
