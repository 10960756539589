
.article{
    overflow: auto;
    /* margin-top: 10px; */
    padding-bottom: 16px;
    background: var(--white);
    /* border-radius: 24px */
}

.resources .pagination{
    width: calc(100% - 20px);
    max-width: 960px;
}


.resources .btnAdddArticle{
    position: fixed;
    bottom: 16px;
    right: 2rem
}

.wrapper-share-article-responsive{
    display: none;
}

.wrapper-share-article{
    position: absolute;
    width: 100%;
    height: 100%;
}

.wrapper-share-article .share-article{
    position: absolute;
    right: 8px;

}


@media (max-width: 600px){
    .wrapper-share-article-responsive {
        display: flex;
    }

    .wrapper-share-article .share-article{
        /* display: none; */
        top: 10px;
    }
}