.cms{
    background-color: rgba(0, 0, 0, 0.150);
    flex-grow: 1;
    position: relative;
    overflow: hidden;
    /* height: max-content; */
    /* background: blue; */
    min-height: calc(100vh - 64px);
    height: auto;
}

.cms .resources{
    flex-grow: 1;
    width: 100%;
}


.cms .containerCms{
    flex-grow: 1;
}


