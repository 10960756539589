.richEditor{
    background-color: var(--white);
}

.richEditor,
.richEditor-constrols-wrapper{
    position: relative;
}

/* .richEditor-constrols-wrapper:hover,
.richEditor-constrols-wrapper:hover ~ .richEditor-input{
    background-color: var(--gray);
    border-radius: 4px;
} */

.richEditor-edit::after,
.richEditor-edit .richEditor-constrols-wrapper:after{
    content: "";
    width: calc(100% - 16px);
    border-bottom: 2px solid var(--gray);
    position: absolute;
    left: 8px;
    bottom: -1px;
}

/* .richEditor-constrols-wrapper:hover::after{
    border-bottom: 1px solid var(--dark);
} */

.richEditor-close{
    margin-left: auto;
    border-radius: 50%;
    background-color: var(--white);
}

.dragIndicator{
    position:absolute;
    top: 0;
    right:0;
    height:40px;
    border-radius: 50%;
    background-color: var(--white);
}

.dragIndicator button{
    cursor: grab;
}

.richEditor-btn{
    min-width: initial !important;
    padding: 0px 4px !important;
}
.richEditor-btn:hover{
    color: var(--dark);

}

.richEditor-input{
    font-family: 'Roboto';
    color: var(--dark);
    padding: 8px;
    display: flex;
    flex-direction: column;
}

.richEditor-edit .richEditor-input{
    min-height: 100px;
}

.richEditor-input h2 {
    padding-top: 10px;
}


.DraftEditor-editorContainer{
    z-index: initial !important;
}

.public-DraftStyleDefault-ltr {
    text-align: justify;
    line-height: 1.5;
}

@media only screen and (max-width: 500px){
    .richEditor-constrols-wrapper{
        padding-bottom: 4px;
    }
    .richEditor-constrols{
        flex-direction: column;
    }
}