
.articleVisor{
    min-height: 600px;
}

.resources .fab{
    position: fixed;
    bottom: 16px;
    right: 2rem
}

.containerResources{
    min-height: 600px;
}

/* revisar ancho de card que envuelve paginacion  */
/* en css cuando esta en 660px exacto no se ven ambas tarjetas de articles, le subi un pixel a cardArticleResponsive */
/* .articleVisor .pagination{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
@media (min-width: 660px) {
    .articleVisor .pagination{
        max-width: 450px;
    }
    
} */