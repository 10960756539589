
.cardJob{
    height: auto;
    justify-content: space-between;
    align-items: center;
    position: relative;
    border-radius: 12px !important;
    /* background-color: rgba(255, 255, 255, 0.8) !important;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px); */
    /* max-width: 450px; */
}
.cardJob_priority::before{
    pointer-events: none;
    content: "";
    position: absolute;    
    bottom: -10px;
    right: 0;
    height: 100%;
    width: 100%;
    background-color: var(--white);
    background-image: url(../../assets/images/Rect\ Light.svg);
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: auto 120px;
    z-index: 0;
    opacity: 0.8;
}

.cardJob_title{
    flex-wrap: wrap;
    justify-content: space-between;
    cursor: pointer;
}


.cardArticleResponsive:hover{
    background: var(--gray);
}

.cardArticleResponsive .container_date{
    position: absolute;
    height: 40px;
    padding: 5px 10px;
    top: -40px;
    left: 0;
    background-color: var(--primary);
    border-top-right-radius: 5px;
}

@media (min-width: 661px) {
    .cardArticleResponsive{
        display:none !important
    }
  }

  .cardJob-containerChip{
    flex-wrap: wrap;
  }
  .cardJob-chip{
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 8px;
    padding-right: 8px;
    white-space: nowrap !important;
  }
