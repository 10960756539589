.cardTestimony_wrapper{
  width: 330px;
  overflow: hidden;
  border-radius: 14px 50px 14px 50px;
}
.cardTestimony_card{
  position: relative;
  overflow: hidden;
  margin: 0 !important;
}

.cardTestimony{
  width: 100%;
  height: auto;
  min-height: 240px;
  transition: 0.2s;
  /* overflow-anchor: none; */
  justify-content: space-between;
}

.sectionTestimonials .cardTestimony-quote{
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.418);
  border-radius: 50%;
  background-color: var(--primary);
}

.sectionTestimonials h2,
.sectionTestimonials span{
  pointer-events: none;
  font-size: 1rem;
}
.sectionTestimonials .cardTestimony p{
  pointer-events: none;
  font-size: 0.85rem;
  /* line-height: 150%; */
}


.sectionTestimonials .quote_rotate{
  animation: quoteRotate 0.5s linear;
}

@keyframes quoteRotate { 
  100% { 
      -webkit-transform: rotateY(360deg); 
      transform:rotateY(360deg); 
  } 
}

@media (max-width: 1000px) {
  .cardTestimony_wrapper{
    width: 100%;
    max-width: 550px;
  }
  .cardTestimony{
    min-height: 180px;
  }
}


  /* @media (max-width: 1250px) {
    .cardTestimony{
      width: 90%;
      max-width: 600px;
      min-height: 35vh;
      height: auto;
    }
    
  }
*/
  @media (orientation: portrait) and (max-width: 600px) {
    .cardTestimony_wrapper{
      border-radius: 14px 34px 14px 34px;
    }

    .sectionTestimonials h2,
    .sectionTestimonials span{
      font-size: 0.9rem !important;
    }
    .cardTestimony{
      min-height: initial;
    }
  } 
