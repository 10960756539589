/* preloaderLogo */
.preloader{
    position: fixed;
    z-index: 10000;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
    /* animation: dissolve 0.5s reverse; */
}

.mt{
    margin-top: -58px;
}
.preloaderLogo{
    width: 150px;
    max-width: 30vw;
    animation: dissolve 1.5s;
}

.dismissPreloader{
    animation: dissolve 0.25s reverse;
    animation-fill-mode: forwards;
    /* animation-delay: 2s; */
}

@keyframes dissolve {
    from {opacity: 0;}
    to {opacity: 1}
  }


.MuiLinearProgress-dashed,
.MuiLinearProgress-bar,
.MuiLinearProgress-bar{
    border-radius: 50px;
}
