.modalMap{
    max-width: 900px;
}

.iframeMap{
    width:90vw; 
    height:50vh;
    max-height: 100%;
    border:none;
    overflow:hidden;
    border-radius: 4px;
    /* box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%); */
    margin: 5px 0;
}

.place-card {
    display: none !important;
    padding: 9px 4px 9px 11px;
}