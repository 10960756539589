
.teamCard_wrap{
    width: 50%;
    max-width: 500px;
    position: relative;
}

.teamCard_wrap .teamCard{
    max-width: 400px;
    cursor: pointer;
    border-radius: 60px 16px 16px 60px !important;
    justify-content: flex-start !important
}

.teamCard_wrap_fixed .teamCard,
.teamCard_wrap .teamCard:hover{
    background-color: var(--gray) !important;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.096), 0px 5px 8px 0px rgba(0, 0, 0, 0.061), 0px 1px 14px 0px rgba(0, 0, 0, 0.048);
    transition: 0.1s;
}

.teamCard_wrap .teamCard .teamCard_containterAvatar{
    height: 120px;
    width: 120px;
    min-height: 120px;
    min-width: 120px;   
    position: relative;
    align-items:flex-end
}

.teamCard_wrap .teamCard .teamCard_containterAvatar .backgroundAvatar{
    position: absolute;
    background-color: var(--primary);
    height: 100%;
    width: 100%;
    top: -5px;
    left: -10px;
    border-radius: 35px 60px 60px 70px;
    opacity: 0;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.096), 0px 5px 8px 0px rgba(0, 0, 0, 0.061), 0px 1px 14px 0px rgba(0, 0, 0, 0.048);

}
.teamCard_wrap_fixed .teamCard .teamCard_containterAvatar .backgroundAvatar,
.teamCard_wrap .teamCard:hover .teamCard_containterAvatar .backgroundAvatar{
    opacity: 1;
}

.teamCard_containterAvatar .teamCard_Image{
    height: 100%;
    width: 100%;
    border-radius: 50%;
    z-index: 1;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 0px 3px #00000040;
    background: var(--white);
    user-select: none;
}
.teamCard_containterAvatar .teamCard_Image img{
    height: 100%;
    width: 100%;
    user-select: none;
}

.teamCard_containterAvatar .teamCard_Image .mediaDefault{
    position: absolute;
    height: 100%;
    width: auto;
    z-index: 2;
    user-select: none;
}

/* iconEdit */
.teamCard_wrap .teamCard_containerBody_iconEdit{
    position: absolute;
    right: 10px;
    bottom: 10px;
    display: none
}
.teamCard_wrap:hover .teamCard_containerBody_iconEdit{
    display: initial;
}


/* noActive CMS */
.teamCard_containterAvatar_disable{
    filter: grayscale(0.8);
}

/* fixed */
.teamCard_wrap_fixed{
    width: 100%;
    max-width: none;
    justify-content: center;
}
.teamCard_wrap_fixed .teamCard{
    max-width: 1100px;
    width: 100%;
}

.teamCard_wrap_fixed .teamCard_containerBody span{
    font-size: 1.25rem;
}


/* teamCard_wrap_min  */

.teamCard_wrap_min{
    width: auto;
    padding:16px;
    max-width: 500px;
    min-width: 250px;
}

.teamCard_wrap_min .teamCard .teamCard_containterAvatar{
    height: 75px;
    width: 75px;
    min-height: 75px;
    min-width: 75px;
}

.teamCard_wrap_min  .teamCard_containerBody{
    position: relative;
    padding-left: 8px !important;
    padding-right: 16px !important;
}
.teamCard_wrap_min  .teamCard_containerBody h2{
    font-size: 1.3rem;
}
.teamCard_wrap_min .teamCard_containerBody span{
    font-size: 0.8rem;
}   


@media (max-width: 750px) {

    .teamCard_wrap .teamCard .teamCard_containterAvatar{
        height: 75px;
        width: 75px;
        min-height: 75px;
        min-width: 75px;
    }
    .teamCard_containerBody{
        padding-left: 8px !important;
    }
    .teamCard_containerBody h3{
        font-size: 1rem;
    }
    .teamCard_wrap_fixed .teamCard_containerBody span{
        font-size: 0.8rem;
    }
}   

@media (max-width: 500px) {

    .teamCard_wrap{
        width: 100%;
        max-width: 100%;
    }
    .teamCard_wrap .teamCard{
        max-width: none;
    }

    
}
@media (max-width: 1000px) {

    .teamCard_wrap_cms{
        width: 100%;
        max-width: 100%;
    }
    
}