/* .postArticleBy{
    cursor: pointer;
    background-color: var(--gray);
} */
.containerAvatar{
    width: 80px;
    min-width: 80px;
    position: relative;
    justify-content: flex-end;
}
.containerAvatar .avatar{
    width: 70px;
    height: 70px;
}
.backGroundAvatar{
    background-color: var(--primary);
    left: 0;
    height: 70px;
    width: 79px;
    position: absolute;
    border-radius: 35px 61px 61px 70px ;
}

.postArticleBy_autocomplete{
    width: 350px;
}

@media (max-width: 450px) {
    .postArticleBy_autocomplete{
        width: 80vw;
    }
  }

  /* MSSQLSERVER */
