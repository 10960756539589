.modalSendResume{
background-color: rgba(0, 0, 0, 0.596);
}

/* .modalSendResume .MuiPaper-root{
    min-width: fit-content;
    margin: 0;
    width: calc(100% - 32px)
} */

.modalSendResume .containerFileResume{
    position: relative;
    /* height:100px; */
    border-radius: 4px;
    border: 1px solid var(--secondary);
    border-style: dotted;
}
.modalSendResume .containerFileResume:hover{
    background-color: var(--gray);
}
.modalSendResume .containerFileResume .inputFileResume{
    opacity:0;
    width:100%;
    height:100%;
    position: absolute;
    cursor: pointer;
}
.modalSendResume{
    user-select: none;
}

.modalSendResume .emptyResumeFile{
    border: 1px solid var(--primary);
}
.iconClose{
    position: absolute;
    right: 5px;
    top: 5px;
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
.spinner {
    width: 50px;
    height: 50px;
    border: 5px solid #f3f3f3;
    border-top: 5px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    z-index: 9998;
}

.loading-overlay {
    z-index: 1301; /* Should be higher than the dialog's z-index */
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}