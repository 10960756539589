.listDragArticle {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto
  }
  .list-container {
    display: flex;
    background-color: #eee;
    flex-direction: column;
  }
  .item-container {
    position: relative;
    cursor: auto !important;
  }

  /* .dragIndicator{
    position: absolute;
    border-radius: 50%;
    background-color: var(--white);
    top: -1;
    right: 50px;
    height:40px;
  }
  
  .dragIndicator button{
    cursor: grab;
  } */