
@media (max-width: 500px) {
    .modalTeamCardEdit .MuiDialogContent-root{ 
        padding: 8px !important;
    }
}

@media (max-width: 700px) and (orientation: landscape) {
    .modalTeamCardEdit_teamCard_out{
        display: none !important;
    }
    .modalTeamCardEdit_teamCard_into{
        display:initial
    }
}

.inputFileHIdden{
    display: contents;
    opacity:0;
    pointer-events:"none" 
}
/* containterAvatar */
.modalTeamCardEdit_containterAvatar{
    position: relative;
    border-radius: 50% !important;
    padding: 0 !important;
    height: 120px;
    width: 120px;
    min-height: 120px !important;
    min-width: 120px !important;
    overflow: hidden;
}

.modalTeamCardEdit_containterAvatar img{
    height: 100%;
    width: 100%;
    border-radius: 50%;
    pointer-events: none;
    background-color: var(--secondary);
}

.modalTeamCardEdit_containterAvatar .iconChangeImage{
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    display: none;
    z-index: 1;
    pointer-events: none;
}

.modalTeamCardEdit_containterAvatar .iconChangeImage_noImg,
.modalTeamCardEdit_containterAvatar:hover > .iconChangeImage{
    display: flex;
}

.modalTeamCardEdit .inputNameEmployee input{
    color: var(--primary);
    font-size: 1.5rem;
}


@media (max-width: 600px){


    .modalTeamCardEdit_containterAvatar{
        height: 75px;
        width: 75px;
        min-height: 75px !important;
        min-width: 75px !important;
    }
}

@media (max-width: 520px){
    .modalTeamCardEdit .inputNameEmployee input{
        font-size: 14pt !important;
    }
}