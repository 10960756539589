.resources{
    overflow: auto;
    height: 100%;
}

.files_itemList{
    padding-right: 96px !important;
}


