.section{
    min-height: 450px;
    justify-content: space-evenly;
}

.section_nmh{
    min-height: inherit !important;
}

.section_text{
    white-space:pre-line !important;
}
