.jobsCustom{
    justify-content: space-evenly;
    align-items: flex-end;
    flex-wrap: wrap;
}

.jobsCustom .containerInput{
    justify-content: space-evenly;
    align-items: flex-end;
    flex-wrap: wrap;
    width: 90%;
}
.jobsCustom .inputKeywords{
    width: 45%;
}
.jobsCustom .inputLocations{
    width: 45%;
}

.jobsCustom .jobsCustom_button{
    border-radius: 50%; 
    background: var(--primary);
    /* margin:"auto 0" */
}

@media (max-width: 570px) {
    .jobsCustom .containerInput{
        width: 100%;
    }
    .jobsCustom .inputKeywords{
        width: 100%;
    }
    .jobsCustom .inputLocations{
        width: 100%;
    }   
    .jobsCustom .jobsCustom_button {
        margin-top: 16px;
        margin-left: auto;
    }

    .jobsCustom .containerInput .inputKeywords,
    .jobsCustom .containerInput .inputLocations{
        margin-bottom: 16px;
    }
}