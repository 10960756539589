/* .footer_input-email input{
    color: var(--white) !important;
    padding-left: 4px;
}
.footer_input-email > div::before,
.footer_input-email > div::after{
    border-bottom: 0.5px solid var(--white) !important;
}
.footer_input-email > label{
    color: var(--white) !important;
}
 */

.newsletters-input{
    background: var(--gray);
    overflow: hidden;
    border-radius: 4px;
    padding: 0 8px;
    position: relative;
}
.newsletters-input-icon{
    position: absolute;
    left: 2px;
    height: 100%;
}
.newsletters-input-input{
    padding-left: 24px;
}

.subscribeAnimation{
    pointer-events: none;
    position:absolute; 
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.subscribeAnimation img{
    width: 100%;
    pointer-events: none;
}