.richEditorSiteContents{
    /* background-color: var(--white); */
}

.richEditorSiteContents,
.richEditorSiteContents-constrols-wrapper{
    position: relative;
}

/* .richEditorSiteContents-constrols-wrapper:hover,
.richEditorSiteContents-constrols-wrapper:hover ~ .richEditorSiteContents-input{
    background-color: var(--gray);
    border-radius: 4px;
} */

/* .richEditorSiteContents-edit::after, */
.richEditorSiteContents-edit .richEditorSiteContents-constrols-wrapper:after{
    content: "";
    width: calc(100% - 16px);
    border-bottom: 2px solid var(--white);
    position: absolute;
    left: 8px;
    bottom: -1px;
}

/* .richEditorSiteContents-constrols-wrapper:hover::after{
    border-bottom: 1px solid var(--dark);
} */

.richEditorSiteContents-close{
    margin-left: auto;
    border-radius: 50%;
    background-color: var(--white);
}

.dragIndicator{
    position:absolute;
    top: 0;
    right:40;
    height:40px;
    border-radius: 50%;
    background-color: var(--white);
}

.dragIndicator button{
    cursor: grab;
}

.richEditorSiteContents-btn{
    min-width: initial !important;
    padding: 0px 4px !important;
}
.richEditorSiteContents-btn:hover{
    color: var(--dark);

}

.richEditorSiteContents-input{
    font-family: 'Roboto';
    color: var(--dark);
    padding: 8px;
    display: flex;
    flex-direction: column;
}

.richEditorSiteContents-edit .richEditorSiteContents-input{
    min-height: 100px;
}

.richEditorSiteContents-input h2 {
    padding-top: 10px;
}


.DraftEditor-editorContainer{
    z-index: initial !important;
}

.public-DraftStyleDefault-ltr {
    text-align: justify;
    line-height: 1.5;
}

@media only screen and (max-width: 500px){
    .richEditorSiteContents-constrols-wrapper{
        padding-bottom: 4px;
    }
    .richEditorSiteContents-constrols{
        flex-direction: column;
    }
}