.banner{
    flex-grow: 1;
    color: var(--white);
    position: relative;
    overflow: hidden;
    height: calc(95vh - 58px);
    min-height: 350px;
    max-height: 1200px;
}

.banner-text{
    max-width: 90%;
    width: 1000px;
    text-align: left;
}

.banner-background{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.banner-background::before{
    content: "";
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.400);
    z-index: 1;
}

.banner-background img{
    position: absolute;
    /* width: 100%; */
    /* height: auto; */
    /* min-width: 100%;
    /* transition: 5s; */
    min-height: 100%; 
    min-height: 100%;
    background-color: white;
}
.banner-background video{
    position: absolute;
    width: 100%;
    min-height: 100vh;
    height: auto;
    background-color: white;
}

@media (max-width: 1200px) {
    .banner-background video{
        height: 100%;
        width: max-content;
        min-width: 100vw;
    }
}

@media (max-width: 920px) {
    .banner-background img{
        /* height: 100%; */
        width: max-content;
    }
    
    .banner h1{
        font-size: 3rem;    
    }
    .banner h2{
        font-size: 1.5rem;    
    }
}

@media (orientation: portrait) {
    .banner{
        height: calc(100vh - 58px);
    }

    .banner-background img{
        /* height: 100%;*/
        width: max-content; 
    }
}


@media (orientation: landscape) {
    .banner-background img{
        /* height: max-content; */
        /* width: auto; */
    }
}

/* 
@media (orientation: landscape and ) {
    .banner{
        height: calc(100vh - 58px);
    }

    .banner-background img{
        height: 100%;
        width: max-content;
    }
} */
