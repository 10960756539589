
.headerImage{
    width: 100%;
    height: 50vh;
    min-height: 260px;
    overflow: hidden;
    position: relative;
    max-height: 500px;
}

.headerImageBorderRadius{
    border-radius: 0 0 24px 24px;
}

.headerImage .containerHeaderImage{
    position: absolute;
    width: 100%;
    height: 100%;
}

.headerImage .containerHeaderImage img,
.headerImage .containerHeaderImage video{
    height:auto;
    min-width: 100%;
    /* width: 100%; */
}

/* .headerImage .containerHeaderImage .isImageWidthSmall{
    height: 100%;
    width:auto;
} */

.headerImage .containerHeaderImage::after{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.363);
}

.headerImage .containerHeaderImage-Loading{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--primary);
}

.headerImage .btnChangeImage{
    position: absolute;
    right: 16px;
    top: 16px;
}
.headerImage .btnDeleteImage{
    position: absolute;
    left: 16px;
    top: 16px;
}

.headerImage .headerText{
    position: relative;
    color: var(--white);
}

/* animate */

.headerImageAnimate .containerHeaderImage::after{
    background-color: rgba(0, 0, 0, 0.863);
}
.headerImageAnimate .headerText{
    transform: scale(1.1);
}
.headerImageAnimate .containerHeaderImage  img{
    transform: scale(1);
}

/* animationStart */
.animationStart .containerHeaderImage::after{
    transition: 0.8s;
    background-color: rgba(0, 0, 0, 0.5);
}


.animationStart .containerHeaderImage  img{
    transition: 5s;
    transform: scale(1.1);
}

.animationStart .headerText{
    transition: 5s;
    transform: scale(1);
}

.headerImage h1{
    /* padding: 0 8px; */
    padding: 0 20px;
}

@media (orientation: portrait) {
    .headerImage .containerHeaderImage img,
    .headerImage .containerHeaderImage video{
        height:100%;
        width: auto;
        min-width: -webkit-fill-available;
        min-height: -webkit-fill-available;
        object-fit: cover;
    }
    .headerImage h1{
        padding: 0 16px;
    }

}

@media (max-width: 500px) {

    .headerImage{
        height: 35vh;
        min-height: 35vh;;
    }

    .headerImage h1{
        font-size: 2.5rem;
    }

    .headerImageBorderRadius{
        border-radius: 0;
    }
}


/* ------ */