.imageSimple{
    width: 100%;
    margin: auto;
    border-radius: 4px;
    position: relative;
    min-height: 40px;
    background: white;
}

.imageSimple_hover{
 background-color: var(--gray);
 cursor: pointer;
}

.imageSimple > img{
    height: auto;
    border-radius: 12px;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    color: var(--dark);
}

.imageClose{
    position:absolute;
    top: 0;
    right:0;
    height:40px;
    border-radius: 50%;
    background-color: var(--white);
}

.dragIndicator{
    position:absolute;
    top: 0;
    right:0;
    height:40px;
    border-radius: 50%;
    background-color: var(--white);
  }
  
  .dragIndicator button{
    cursor: grab;
  }