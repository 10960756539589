
.navBar .main_btn{
    display: none;
}
.navBar .main_items button{
    font-family: 'Montserrat', sans-serif !important;
}

.navBar .main_items_home{
    display: none;
}

.navBar .logoAsap{
    width: auto;
}

@media (max-width: 800px) {

    .navBar .main_items_home{
        display: flex;
        justify-content: center;
    }
    .main_items_home_button{
        width: 100%;
    }
    .navBar .main_btn{
        display: flex;
    }

    .navBar .main_items{
        background-color: var(--white);
        z-index: 1;
        position: absolute;
        display: flex;
        flex-direction: column;
        height: 100vh;
        width: 100vw;
        top: 54px;
        left: 100%;
        transition: 0.25s;
        padding-top: 16px;
        background-color: var(--white);
        background-image: url(../../assets/images/Rect\ Light.svg);
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: auto 60vh;

    }
    .navBar .main_items button{
        padding: 16px;
    }

    .navBarActive .main_items{
        left: 0;
    }
}