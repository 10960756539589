
.cardPagination{
    border-radius: 12px !important;
}

@media (max-width: 500px) {
    .pagination button{
        min-width: 30px;
        height: 30px;
    }
}
