/* 
.text input{
    background: none;
    border: none;
    width: auto;
    outline: none;
    color: var(--white);
    font-family: "Roboto","Helvetica","Arial",sans-serif;
}
.text input::placeholder {
    color: var(--white);
} */

/* .text{
    background-color: red;
    width: inherit;
} */

.textFixed,
.text_input{
    max-width: calc(100% - 50px);
    word-wrap: break-word;
}

.text .text_input{
    background: none;
    border: none;
    outline: none;
    color: var(--white);
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    resize:none;
    overflow:hidden;
}
.text .text_input::placeholder {
    color: var(--white);
  }
  

  .text .fontType_title{
    text-align: center;
    font-size: 3.75rem;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-family: 'Montserrat', sans-serif !important;
    font-weight: 500 !important;
}

/* hasta 520 */
@media (max-width: 520px) {
    .text .fontType_title{
        font-size: 25pt !important;
    }
  }