
  
.team .deparment{
    /* margin: 5px 0; */
    border-radius: 8px;
}

.team .deparment:hover{
    /* background: rgb(243, 243, 243); */
}


.team .deparment h2 .rotate{
    transform: rotate(90deg);
}


.team .containterEmployees{
    flex-wrap: wrap;
    height: 0;
    opacity: 0;
    pointer-events: none;
    justify-content: space-between;
}

.team .containterEmployeesActive{
    height: auto;
    opacity: 1;
    transition: 0.7s;
    pointer-events: all
}
