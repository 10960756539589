.siteContents-bannerImage,
.siteContents-bannerVideo{
    max-width: 100%;
    height: auto;
}

/* //////////////////////// */
.siteContents .containerImage{
    position: relative;
    height: 450px;
    /* min-height: 450px; */
    width: 400px;
    align-items: center;
}

.siteContents .containerImage{
    position: relative;
    max-height: none;
    height: 100%;
    /* min-height: 40vw;
    width: 50vw; */
    align-items: center;
    min-height: 176px;
    width: 200px;
}

.siteContents .containerImage_background{
    position: absolute;
    background-color: var(--primary);
    height: 100%;
    max-height: 400px;
    width: 80%;
    left: 0;
    max-height: 250px;
    border-radius: 25px 60px 25px 60px;
}



.siteContents .containerImage_image{
    width: 90%;
    height: auto;
    z-index: 100;
    max-width: 400px;
    aspect-ratio: 1.4;
    margin-left: auto;
    border-radius: 25px 50px 25px 50px;
    overflow: hidden;
}



/* listDrag */
.listDragEditor {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto
  }
  .listDragEditor-list-container {
    display: flex;
    background-color: none !important;
    flex-direction: column;
  }
  .listDragEditor-item-container {
    position: relative;
    cursor: auto !important;
  }
/*   
  .listDragEditor {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto
  }
  .listDragEditor .list-container {
    display: flex;
    background-color: #eee;
    flex-direction: column;
  }
  .listDragEditor .item-container {
    position: relative;
    cursor: auto !important;
  } */


.modalSiteContentsEditor .MuiDialogContent-root {
    padding: 0 !important;
} 

  /* Modal */
/* .MuiDialogContent-root {
    padding: 0 !important;
} */


/* .siteContents-input-buttonTitle{
  max-width: 300px;
} */
/* siteContents-input-buttonAction */