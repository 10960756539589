*{
    margin: 0;
    padding: 0;
    display: flexbox;
    box-sizing: border-box;
    /* scroll-behavior: smooth; */
}

body {
 /* background-color: red; */
}

body {
    overflow: overlay;
}

.App{
    max-height: calc(100vh - 58px);
    margin-top: 58px;
    /* scroll under nav  */
    overflow-x: hidden;
    overflow: overlay;
}


main{
    max-width: 2400px;
    margin: auto;
    position: relative;
}

h2,
h1{
    font-family: 'Montserrat', sans-serif !important;
}

.Montserrat{
    font-family: 'Montserrat', sans-serif !important;
}
.Roboto{
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}

a{
    display: contents;
    color:inherit;
    text-decoration: none
}

.contents{
    display: contents !important;
}

.flex{
    display: flex;
}
.col{
    flex-direction: column;
}
.row{
    flex-direction: row;
}
.wrap{
    flex-wrap: wrap;
}

.center{
    justify-content: center;
    align-items: center;
}
.jccenter{
    justify-content: center;
}
.aicenter{
    align-items: center;
}

/* dimentions */
.w100{
    width: 100%;
}
.w50{
    width: 50%;
}

.mw800{
    max-width: 800px;
}
.mw900{
    max-width: 900px;
}
.mw1000{
    max-width: 1000px;
}
.mw1200{
    max-width: 1200px;
}
.mwmax{
    /* max-width: 1400px; */
}
.minh100{
    min-height: 100vh;
}

.minhCont{
    min-height: calc(100vh - 58px);
}

/* text */
.tbold{
    font-weight: 500 !important;
}

.tcenter{
    text-align: center;
}
.tr{
    text-align: right;
}
.tl{
    text-align: left;
}
.tj{
    text-align: justify !important;
}

.pointer{
    cursor:pointer
}
/* animation */

.bounce:hover{
    transition: 0.2s;
    transform: scale(1.05) translateY(-2px);
}

svg:hover,
button:hover svg{
    transition: 0.1s;
    transform: scale(1.1);
}


@media (min-width: 1200px) {
    .mw1000,
    .mw1200{
        max-width: 1100px;
    }
}

/* @media (min-width: 550px) { */
/* scroll */
/* width */
::-webkit-scrollbar {
    width: 8px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    /* background: #f1f1f1;  */
    background: #f1f1f100; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(136, 136, 136, 0.664); 
    /* background-color: #888888b4; */
    border-radius: 12px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

/* } */

/* hasta 520 */
@media (max-width: 800px) {
    h1{
        font-size: 32pt !important;
    }

    h2{
        font-size: 14pt !important;
    }
  }
/* hasta 520 */
@media (max-width: 520px) {
    h1{
        font-size: 25pt !important;
    }

    h2{
        font-size: 14pt !important;
    }
  }


  /* Pagination */
@media (max-width: 500px) {
    .pagination button{
        min-width: 30px;
        height: 30px;
    }
}


/* isTouch min*/
@media (hover:none) and (pointer:coarse) and (max-width: 600px){
    .App{
        max-height: initial
    }
}




/* richText */
.bodyContent div > .public-DraftStyleDefault-block{
    display: inline-block;
    margin-bottom: 16px;
}
.bodyContent li > .public-DraftStyleDefault-block{
    display: inline-block;
    margin-bottom: 8px;
}


.bodyContent ul li {
    list-style-type: none;
    margin-left: 16px !important;
    padding-right: 40px;
    display: flex;
    flex-direction: row;
}
.public-DraftStyleDefault-listLTR .public-DraftStyleDefault-ltr{
    text-align: left;
}

.bodyContent ul li:before {
    content: url('./assets//icons/chevrons-right-solid-24.png');
    height: fit-content;
    margin-right: 16px;
    /* margin-bottom: 8px; */
}

.bodyContent ol li {
    margin-left: 40px !important;
    padding-right: 40px;
    text-align: left;
}

.bodyContent ol li:before{
    color: var(--primary);
    font-weight: bolder;
    font-family: 'Montserrat', sans-serif !important;
    font-size: 18px;
}

@media (max-width: 650px) {
    .bodyContent ul li {
        margin-left: 0px !important;
        padding-left: 8px;
        padding-right: 20px;
    }
    .bodyContent ol li {
        margin-left: 16px !important;
        padding-left: 16px;
        padding-right: 20px !important;
    }
    .bodyContent ol li:before {
        margin-left: 10px;
    }
}

button,
.MuiButton-root{
    border-radius: 50px;
}


.noSelect{
    user-select: none;
    pointer-events: none;
} 



input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}

input[data-autocompleted] {
    background-color: transparent !important;
}