.capitalize{
    /* text-transform: capitalize; */
}

.breadcrumbsCustomized_text{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 85vw;
    /* font-family: 'Montserrat', sans-serif !important; */
}

.breadcrumbsCustomized li{
    /* margin: 0; */
    margin-left: 1px;
    margin-right: 1px;
}