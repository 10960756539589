.jobsByLocation{
    flex-wrap: wrap;
    justify-content: space-between;
}

.itemLocation_btn{
    border-radius: 16px !important
}

.itemLocation_btn:last-child{
    margin-right: auto;
}

.jobsByLocation .itemLocation_chip{
    cursor: pointer !important;
    background-color: var(--white) !important;
    min-width:100px
}

@media (max-width:900px) {
    .jobsByLocation{
        padding: 8px;
        justify-content: initial;
    }
    .jobsByLocation .itemLocation_chip{
        min-width:95px
    }
}
