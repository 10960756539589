.modalTeamCard .MuiPaper-root{
    min-width: fit-content;
    margin: auto 10px;
    width: calc(100% - 32px)
}

.modalTeamCard .MuiBackdrop-root{
    /* background-color: var(--white); */
    background-color: rgba(0, 0, 0, 0.8);
}

.modalTeamCard_teamCard_out{
    display: initial;
}
.modalTeamCard_teamCard_into{
    display:none
}

@media (max-width: 500px) {
    .modalTeamCard .MuiDialogContent-root{ 
        padding: 8px !important;
    }
}

@media (max-width: 700px) and (orientation: landscape) {
    .modalTeamCard_teamCard_out{
        display: none !important;
    }
    .modalTeamCard_teamCard_into{
        display:initial
    }
}