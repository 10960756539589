.log{
    flex-grow: 1;
    color: var(--white);
    position: relative;
    overflow: hidden;
    height: calc(100vh - 58px);
    background: #1616168c;
}

.log .containerTitleLog{
    width: 50%;
    height: 100%;
    position: inherit;
}

.log .containerTitleLog .imageHeader{
    position:absolute; 
    z-index:-1; 
    width:100%; 
    height:100%;
    background: var(--primary);
}
.log .containerTitleLog .imageHeader img{
    width:fit-content; 
    height:fit-content;
}
.log .containerFormLog{
    background: var(--primary);
    width: 55%;
    height: 100%;
}
.log .containerFormLog .actionInput{
    position: absolute;
    bottom: 0;
    right: 0;
}

.log .containerFormLog .logoForm{
    width: 100px;
}

@media (max-width: 750px) {
    .log .containerTitleLog{
        display: none;
    }
    .log .containerFormLog{
        width: 100%;
    }
}