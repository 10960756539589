.iframe{
    width: 100%;
    margin: auto;
    border-radius: 4px;
    position: relative;
    min-height: 40px;
    border-radius: 12px;
    overflow: hidden;
    background: white;
}

.imageSimple_hover{
 background-color: var(--gray);
 cursor: pointer;
}

.list-container iframe,
.article iframe{
    min-width: calc(100%);
    max-width: 100%;
    min-height: 400px;
}

.closeIframe{
    position:absolute;
    top: 0;
    right:0;
    height:40px;
    border-radius: 50%;
    background-color: var(--white);
}

.dragIndicator{
    position:absolute;
    top: 0;
    right:0;
    height:40px;
    border-radius: 50%;
    background-color: var(--white);
}

.dragIndicator button{
    cursor: grab;
}


@media (max-width: 500px) {
    iframe{
        min-height: initial;
    }
}