
.containerFeaturedIcons{
  justify-content: space-evenly;
  /* width: 100vw; */
}

.featuredIcon{
  margin: 10px;
  width: 30%;
  max-width: 150px;
  height: auto;
  position: relative;
  overflow: hidden;
}

.containerFeaturedIcons .featuredIcon:hover{
  transition: 0.5s;
  transform: scale(1.2);
  filter: none;
}

.containerFeaturedIcons .featuredIcon .featuredIcon-image{
  height: 100%;
  width: 100%;
  filter: grayscale(10);
  /* pointer-events: none; */
}
.containerFeaturedIcons .featuredIcon .mediaDefault{
  height: auto;
  width: 100%;
  position: absolute;
  z-index: 1;
}

