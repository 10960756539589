.modalTeamCardImage .MuiPaper-root{
    min-width: fit-content;
}

.modalTeamCardImage .MuiBackdrop-root{
    /* background-color: var(--white); */
    background-color: rgba(0, 0, 0);
    background-color: var(--white);
    background-image: url(../../assets/images/Rect\ Light.svg);
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: auto 40vh;
}


.modalTeamCardImage{
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.modalTeamCardImage .buttonClose{
    /* position:fixed;  */
    position: absolute;
    right:10px;
    top:10px;
}

.modalTeamCardImage .MuiDialogContent-root{
    padding: 0;
    height: 40vh;
}

.modalTeamCardImage img {
    height: -webkit-fill-available;
}
@media (orientation: portrait){
    .modalTeamCardImage .buttonClose{
        position:fixed; 
        right:20px;
        top:20px;
    }
}

@media (orientation: portrait) and (max-width: 420px) {
    .modalTeamCardImage{
        padding: 8px !important;
    }

    .modalTeamCardImage .MuiDialogContent-root{
        padding: 0;
        height: initial;
        width: 80vw;

    }

    .modalTeamCardImage img {
        height: initial;
        width: -webkit-fill-available;
    }
    
}
