.job{
    background-color: var(--gray);
    min-height: calc(100vh - 64px);
}


.wrapJob{
}

.preWrapJob{
    max-width: 900px;
    justify-content: flex-start;
}

.chip_Job{
    background-color: var(--white) !important;
}
 
.containerJob .bodyJob{
    text-align: justify;
    line-height: 1.5;
    /* padding: 16px; */
}

.containerJob .bodyJob h1{
    display: none;
}

.containerJob .bodyJob u{
    text-decoration: none;
}

.containerJob .bodyJob strong{
    /* display: none; */
}

.containerJob .bodyJob i{
    font-style: normal;
    /* font-family: 'Montserrat', sans-serif !important; */
    /* color: var(--primary); */
    /* margin-top: 15px !important; */
}
.containerJob .bodyJob p{
    text-align: justify !important;
    margin: 10px auto;
}
.containerJob .bodyJob b{
    font-family: 'Montserrat', sans-serif !important;
}
.containerJob .bodyJob ul{
    margin: 15px 0;
}




/* aside */
.asideJob{
    width: 250px;
    min-width: 250px;
    overflow: initial !important;

}

.asideJob-sticky{
    position: sticky;
    position: -webkit-sticky;
    top: 8px;
}
/* .asideJob .cardAsideJob{
    position: sticky;
    position: -webkit-sticky;
    top: 66px;
} */
.asideJob .cardAsideJob h2{
    font-size: 1rem;
}

/* actions resposive */
.actionsJob{
    display: none !important;
}

.asideJob .cardAsideJob-share{
    padding: 16px;
}

.actionsShare{
    display: none !important;
}


/* @media (max-width: 1150px) { */
@media (max-width: 975px) {
    .asideJob{
        display: none;
    }

    .preWrapJob{
        max-width: 100%;
        /* padding-bottom: 130px; */
    }
    .job .headerImage,
    .wrapJob {
        background-color: white;
    }
    .preWrapJob .card{
        box-shadow:none
    } 

    /* actions resposive */
    .actionsJob{
        position: fixed;
        flex-direction: row;
        display: flex !important;
        left: 0;
        position: sticky;
        bottom: 0px;
        z-index: 1;
        /* background-color: var(--gray) !important; */
        /* color:var(--white); */
    }

    .actionsShare{
        display: flex !important;
    }

    .actionsJob .actionsJob-text{
        font-size: 1rem;
    }

    .actionsJob .actionsJob-wrapButtons{
        height: auto;
        justify-content: flex-end;
        flex-wrap: wrap;
    }
    .actionsJob .actionsJob-wrapButtons button {
        min-width: 170px;/* justify-content: space-between; */
    }
   
}

@media (max-width: 800px) {
    .job-bannerText{
        font-size: 2.3rem !important;
        line-height: 100% !important;
        margin-bottom: 10px !important
    }
    .job-bannerText2{
        font-size: 1.8rem !important;
    }

}

@media (max-width: 500px) {

    .actionsJob{
        flex-direction: column;
    }

    .actionsJob .actionsJob-wrapButtons {
        flex-direction: row;
        justify-content: space-between;
    }

    .actionsJob .actionsJob-wrapButtons button {
        min-width: initial;
    }

    .containerJob .bodyJob li{
        text-align: left;
        margin: 7px 0;
        /* line-height: initial; */
    }
    /* .containerJob .bodyJob{
        padding: 0;
    } */
    /* .containerJob .bodyJob ul{
        padding: 0px 15px;
        margin: 5px 0;
    } */

    .job-bannerText{
        font-size: 1.5rem !important;
        margin-bottom: 5px !important
    }
    .job-bannerText2{
        font-size: 1.3rem !important;
    }
}


