.containerJobs{
    background: var(--gray);
    height: 100%;
    background: linear-gradient(148deg, var(--gray) 0%, rgb(207, 207, 207) 20%, rgba(255, 255, 255, 0) 77%),
    linear-gradient(318deg, rgba(0, 0, 0, 0.555) 10%, rgba(255, 255, 255, 0) 77%);
    background: rgba(0, 0, 0, 0.548);
}

.containerJobs:before{
    content: "";
    z-index: -1;
    position: absolute;
    background: var(--gray);
    height: 100%;
    width: 100%;
    background: url(https://images.unsplash.com/photo-1606836591695-4d58a73eba1e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80) no-repeat center;
    background-attachment: fixed;
    background-size: cover;
}

.jobs{
    width: calc(100vw - 250px);
    /* background: yellow; */
    justify-content: flex-start;
    align-items: center;
    min-height: calc(100vh - 114px);
  }

.jobs_container_no_find {
    margin: auto;
}

.jobs_input-filter label,
.jobs_input-filter button,
.jobs_input-filter input{
    color: var(--white) !important;
}

.jobs_input-filter > div::before,
.jobs_input-filter > div::after{
    border-bottom: 0.5px solid var(--white) !important;
}



@media (max-width: 750px) {
    .jobs{
        width: 100vw;
    }
}

/* isTouch min*/
@media (hover:none) and (pointer:coarse) and (max-width: 600px){
    .containerJobs:before{
        background-attachment: initial;
    }
}