.sectionTestimonials{
  background-size: cover; 
  background-position: center;
  user-select: none;
  position: relative;
  min-height: 550px;
  height: auto;
  overflow: hidden;
}

/* .sectionTestimonials::after, */
.sectionTestimonials::before{
  content: "";
  width: 100%;
  position: absolute;
  pointer-events: none;
}

/* .sectionTestimonials::after{
  top: 0;
  height: 40%;
  background: linear-gradient(to bottom, var(--white) 5%,  rgba(255,255,255,0) 100%);
} */
/* .sectionTestimonials::after{
  top: 0;
  height: 100%;
  background: linear-gradient(318deg, rgba(0, 0, 0, 0.555) 10%, rgba(255, 255, 255, 0) 77%);
} */

.sectionTestimonials::before{
  height: 100%;
  bottom: 0;
  /* background: linear-gradient(to top, rgba(0, 0, 0, 0.733) 0%, rgba(255,255,255,0) 80%); */
  /* background: linear-gradient(148deg, var(--gray) 0%, rgb(207, 207, 207) 20%, rgba(255, 255, 255, 0) 77%); */
  background: linear-gradient(148deg, var(--gray) 0%, rgb(207, 207, 207) 20%, rgba(255, 255, 255, 0) 77%),
              linear-gradient(318deg, rgba(0, 0, 0, 0.555) 10%, rgba(255, 255, 255, 0) 77%);
} 


.sectionTestimonials .containerTestimonials{
  justify-content: space-around;
  align-items: center;
  z-index: 1;
  position: relative;
  height: auto;
  min-height: 300px;
}

.sectionTestimonials .iconChange{
    background: var(--primary); 
    border-radius: 50%; 
    /* width:45px; 
    height:45px; */
  }
  
.sectionTestimonials .iconChange_rotate{
  animation: rotate 0.5s;
}

@keyframes rotate { 
  100% { 
      -webkit-transform: rotate(720deg); 
      transform:rotate(720deg); 
  } 
}

@media (max-width: 1000px) {
  .sectionTestimonials .containerTestimonials{
    flex-direction: column;
  }
}

@media (max-width: 550px){
    .sectionTestimonials_title {
        text-align: center !important;
  }
}

/* 
@media (orientation: portrait) and (max-width: 500px) {
  .sectionTestimonials .containerTestimonials{
    flex-direction: column;
  }
} */