.menuAsideJobs{
    /* min-height: 642px; */
    width: 250px;
    z-index: 10;
    background-color: var(--primary);
}

.menuAsideAutocomplete .MuiChip-label{
    text-transform: uppercase
}

.menuAsideJobs_btn{
    display: none !important; 
}

.wrap_menuAside_buttonSearch{
    justify-content: flex-end;
}

.menuAside_buttonSearch{
    height: 48px;
    width: 48px;
    background-color: var(--white);
    border-radius: 50%;
}


@media (max-width: 750px) {
    .menuAsideJobs{
        transition: 0.25s;
        position: absolute;
        z-index: 1;
        height: 100%;
        left: -100%;
        overflow: auto;
    }
    
    .btnToggleFilters{
        cursor: pointer;
    }

    .menuAsideJobs_btn{
        position: absolute;
        right: 0;
        /* padding: 0 16px; */
        padding: 0;
        /* z-index: 1; */
        display: flex !important;    
    }

    .menuAsideJobs_active{
        left: 0;
    }
}
@media (max-width: 550px) {
    .menuAsideJobs{
        max-width: none;
        width: 100%;
        position: fixed;
        max-height: calc(100% - 58px) ;
    }
}