.barSearch{
    margin-left:auto;
    background:var(--gray);
    border-radius:4px;
    width: 350px;
    border-radius: 50px;
    padding: 5px 0px;
    /* padding-right: 10px; */
}

.barSearch form{
    display: contents;
} 


/* .barSearch_input{
    width: 100% !important;
}
.barSearch_input input{
    width: 100% !important;
} */

@media (max-width: 500px) {
    .barSearch{
        width: 100%;
    }
}